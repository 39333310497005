<template>
  <div class="l-default min-h-dvh flex flex-col justify-between" :class="{'confirmation': isConfirmationPage }">
    <AppHeader />
    <slot />
    <div class="w-full flex justify-center my-6">
      <img src="/images/powered-by.svg" width="145px">
    </div>
  </div>
</template>

<script lang="ts" setup>
const route = useRoute()

const isConfirmationPage = ref(false)
watch(() => route.name, () => {
  isConfirmationPage.value = route.name === 'confirmation'
}, { immediate: true })

provide('isConfirmationPage', isConfirmationPage)
</script>

<style>
.l-default {
  background: url('/images/sq-bg@sm.jpg') no-repeat center center fixed;
  background-size: cover;
  background-repeat: no-repeat;
}

.l-default.confirmation {
  background-image: linear-gradient(#fff, #f7f7fa);
  background: none;
  @media screen and (min-width: 640px) {
    background: url('/images/sq-bg@sm.jpg') no-repeat center center fixed;
    background-size: cover;
  }
}
</style>
