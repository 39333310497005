<script setup lang="ts">
import type { NuxtError } from "#app";
const config = useRuntimeConfig();
const props = defineProps({
    error: Object as () => NuxtError,
});

const handleError = () => clearError({ redirect: "/" });
</script>

<template>
  <div class="l-error flex h-dvh flex-col font-['Circular_Std']">
    <AppHeader />
    <div class="container p-8">
      <Card>
        <h2 class="text-2xl text-navy">
          <span class="text-navy-300" v-if="error?.statusCode">[{{ error?.statusCode }}]</span>
          <span class="ml-1">Sorry, something went wrong.</span>
        </h2>
        <p class="my-2 text-navy font-book">
          Please try again or contact us to get help with online
          check-in. If not, please check-in at the front desk on the
          day of your stay.
        </p>
        <template #footer>
          <div class="w-full flex justify-end gap-x-4">
            <SButton
                v-if="config.public.supportUrl"
                :to="config.public.supportUrl"
                target="_blank"
                theme="danger"
                class="text-sm"
                >Contact us</SButton
            >
            <SButton class="text-sm" @click="handleError"
                >Try again</SButton
            >
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<style>
.l-error {
  background: url('/images/sq-bg@sm.jpg') no-repeat center center fixed;
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
