
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.default || mod) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
  }
}
import { default as check_45inqjWKIVB6LkMeta } from "/app/pages/check-in.vue?macro=true";
import { default as confirmation0vlyIlsFyMMeta } from "/app/pages/confirmation.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as payment0EDeMiyhRyMeta } from "/app/pages/payment.vue?macro=true";
export default [
  {
    name: "check-in",
    path: "/check-in",
    meta: check_45inqjWKIVB6LkMeta || {},
    component: () => import("/app/pages/check-in.vue")
  },
  {
    name: "confirmation",
    path: "/confirmation",
    meta: confirmation0vlyIlsFyMMeta || {},
    component: () => import("/app/pages/confirmation.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "payment",
    path: "/payment",
    meta: payment0EDeMiyhRyMeta || {},
    component: () => import("/app/pages/payment.vue")
  }
]