type FormKitInputType = 'text' | 'select' | 'date' | 'email' | 'tel' | 'time' | 'file' | 'checkbox' | 'hidden';
type FormInputType = 'textField' | 'selectField' | 'countryField' | 'nationalityField' | 'phoneField' | 'dateField' | 'timeField' | 'imageField' | 'checkboxField';
type RegistrationFormValuePath = [FormInputType, ...string[]];

const titleField = {
  $formkit: 'select',
  label: 'Title',
  id: 'title',
  options: ["Ms", "Mrs", "Miss", "Mr", "Dr", "Prof", "Rev", "Not Known"],
}

const firstNameField = {
  $formkit: 'text',
  label: 'First Name',
  id: 'firstName',
}

const lastNameField = {
  $formkit: 'text',
  label: 'Last Name',
  id: 'lastName',
}

const emailField = {
  $formkit: 'email',
  label: 'Email address',
  id: 'email',
  validation: 'required|email',
}

const primaryBirthDateField =   {
  $formkit: 'date',
  label: 'Date of Birth',
  id: 'birthDate',
  key: 'birthDate_p',
  validation: `required|primary_birthdate`,
  validationMessages: {
    primary_birthdate: 'You must be at least 18 years old to check in.'
  }
}

const secondaryBirthDateField =   {
  $formkit: 'date',
  label: 'Date of Birth',
  id: 'birthDate',
  key: 'birthDate_s',
  validation: `required|date_before:${todayYMD()}`,
  validationMessages: {
    date_before: "You can't select a date in the future."
  }
}

const countryOfResidenceField = {
  $formkit: 'select',
  label: 'Country of Residence',
  id: 'countryOfResidence',
  options: iso31661Alpha2
}

export const generateRequiredFieldSchema = (guestType: any) => {
  const isPrimaryGuest = guestType === 'PRIMARY'

  const baseSchema = []

  isPrimaryGuest && baseSchema.push(titleField)

  baseSchema.push(
    firstNameField,
    lastNameField
  )

  isPrimaryGuest && baseSchema.push(emailField)

  baseSchema.push(
    isPrimaryGuest ? primaryBirthDateField : secondaryBirthDateField,
    countryOfResidenceField
  )

  return baseSchema.map((inputScheme) => {
    return {
      ...inputScheme,
      name: inputScheme.id,
      key: inputScheme.id,
      placeholder: inputScheme.label,
      required: true,
      validation: inputScheme.validation || 'required',
    }
  })
}

const mapFieldToFormKitInputType = (field: any): FormKitInputType => {
  // handle non-text fields that exist in the prelimnary form
  if (['guestId', 'guestType', 'status', 'isLastNameReadOnly'].includes(field.id)) return 'hidden';
  if (['countryOfResidence', 'title'].includes(field.id)) return 'select';
  if (['birthDate'].includes(field.id)) return 'date';
  if (field.id === 'email') return 'email';

  // handles fields from the initialise form endpoint
  const fieldTypeMap: Record<FormInputType, FormKitInputType> = {
    textField: 'text',
    selectField: 'select',
    countryField: 'select',
    nationalityField: 'select',
    phoneField: 'tel',
    dateField: 'date',
    timeField: 'time',
    imageField: 'file',
    checkboxField: 'checkbox',
  };

  for (const key of Object.keys(fieldTypeMap)) {
    if (key in field) return fieldTypeMap[key as FormInputType];
  }

  return 'text';
}

const getInitialiseFormResponseValuePath = (input: any): RegistrationFormValuePath => {
  if (input?.id === 'document_issue_date') return ['dateField', 'pastDatesAndCurrentDate', 'from']
  if (input?.id === 'document_expiry_date') return ['dateField', 'futureDatesAndCurrentDate', 'to']
  if (input.id === 'nationality') return ['countryField']
  if (input.id === 'phone') return ['textField']

  if (input) {
    if (input.textField) return ['textField'];
    if (input.selectField) return ['selectField'];
    if (input.countryField) return ['countryField'];
    if (input.nationalityField) return ['nationalityField'];
    if (input.phoneField) return ['phoneField'];
    if (input.dateField) return ['dateField'];
    if (input.timeField) return ['timeField'];
    if (input.imageField) return ['imageField'];
    if (input.checkboxField) return ['checkboxField'];
  }

  return ['textField']; // Default to 'textField' if no match is found
}

export const buildSubmitFormPayload = (formData: any, registrationForm: any) => {
  const onlySubmitFields = registrationForm.field.filter((field: any) => field.name.startsWith('form/'))
  const filledFields = onlySubmitFields.map((field: any) => {
    const value = formData[field.name]

    let payload: any = {}
    let [valueRoot, ...path] = getInitialiseFormResponseValuePath(field)

    switch (valueRoot) {
      case 'textField':
      case 'selectField':
      case 'countryField':
      case 'nationalityField':
      case 'phoneField':
      case 'timeField':
      case 'imageField':
        payload = {
          [valueRoot]: {
            value
          }
        }
        break
      case 'checkboxField':
        if (value === undefined) {
          payload = {
            [valueRoot]: {
              value: value || false
            }
          }
        }
        break
      case 'dateField':
        if (value !== undefined && value !== '') {
          // traverse more complex dateField structure paths
          const protoDateValue = dateToProtoDate(value);

          if (path) {
            const payloadStruct = path.reduceRight<{ [key: string]: any }>((acc, pathItem) => ({
              [pathItem]: acc
            }), protoDateValue);

            payload = {
              [valueRoot]: {
                ...payloadStruct,
                value: protoDateValue
              }
            }

          } else {
            payload = {
              [valueRoot]: protoDateValue
            }
          }
        }
        break
    }

    return {
      ...field,
      ...payload
    }
  })

  return {
    ...registrationForm,
    field: filledFields
  }
}

export const mapResponseToFormKitSchema = (field: any[]) => {
  const [rootValuePath] = getInitialiseFormResponseValuePath(field)

  const baseSchema = {
    $formkit: mapFieldToFormKitInputType(field),
    key: field.name,
    id: field.id,
    name: field.name,
    label: field.displayName,
    validation: field.mandatory ? 'required' : '',
    required: field.mandatory
  }

  if (['nationality', 'countryOfResidence'].includes(baseSchema.id)) {
    baseSchema.options = iso31661Alpha2
  }

  if (baseSchema.$formkit === 'select') {
    if (!field.countryField) {
      baseSchema.options = field[rootValuePath].option?.map((option) => {
        return {
          label: option.displayName,
          value: option.value
        }
      }) ?? []
    } else {
      baseSchema.options = iso31661Alpha2
    }
  }

  return baseSchema
}
