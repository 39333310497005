<template>
  <div class="text-[#7F8084] text-xs col-span-2">
    <p class="inline-block">*By completing online check-in, you are accepting the Hotel’s Policy.</p>
    <details class="mt-1 inline-block" v-if="property?.termsAndConditions">
      <summary class="cursor-pointer text-teal underline ml-1 hover:text-opacity-75">Read Full Policy here</summary>
      <div class="p-2 border rounded-md bg-white mt-1" v-html="property?.termsAndConditions?.replaceAll('\n', '<br/>')"></div>
    </details>
  </div>
</template>

<script lang="ts" setup>
const { property } = storeToRefs(useWebsiteStore())
</script>

<style scoped>
details > summary {
  list-style: none;
}
details > summary::-webkit-details-marker {
  display: none;
}
</style>
