<template>
  <Card :image-src="'/images/stay-detail-default-hero.jpg'" v-if="ready" class="max-w-sm">
    <h3 class="text-[24px] font-medium text-slate-800 tracking-wide">Find your stay</h3>
    <p class="text-navy-600 !mt-1 text-md">Add your 7 letter Shackle booking reference to find your stay to start check-in</p>
    <ReferenceCodeInput :error="!!claimError" />
    <p class="!mt-2 text-xs text-alert-danger" v-if="claimError">{{ claimError }}</p>
    <template #footer>
      <SButton :disabled="!canFindStay" class="w-full h-14 lg:h-16 font-normal lg:text-lg" @click="onFindStay">Find stay</SButton>
    </template>
  </Card>
</template>

<script setup lang="ts">
import { MP_CLAIMED_BY_REF } from '~/plugins/mixpanel.client';
const { referenceCode, claimError, isFetchingClaim } = storeToRefs(useWebsiteStore());
const { claimByReferenceCode } = useWebsiteStore();
const { $mixpanel } = useNuxtApp();
const router = useRouter();

const ready = ref(false);
onMounted(() => { ready.value = true; });
watch(() => referenceCode.value, () => {
  if (!codeContainsNumbers.value) claimError.value = null;
});

const codeContainsNumbers = computed(() => referenceCode.value.match(/\d+/g));

const canFindStay = computed(() => {
  return referenceCode.value.length === 7 && !isFetchingClaim.value && !claimError.value;
});

const onFindStay = async () => {
  if (!referenceCode.value) return;
  claimError.value = null;
  try {
    await claimByReferenceCode(referenceCode.value);
    $mixpanel(MP_CLAIMED_BY_REF, { referenceCode: referenceCode.value });
    router.push('/check-in')
  } catch (error) {
    claimError.value = 'Please double check booking reference or try again later';
  }
};
</script>
