<template>
  <div class="flex space-x-2 md:space-x-4">
      <a
          :href="config.public.supportUrl"
          target="_blank"
          class="!text-inherit !no-underline"
          v-if="config.public.supportUrl"
      >
          <PhosphorIconQuestion
              size="24"
              class="cursor-pointer sm:hidden"
          />
          <span
              class="hidden sm:block text-inherit hover:opacity-50"
              >Need help?</span
          >
      </a>
      <button
          class="flex space-x-1 items-center text-inherit hover:opacity-50"
          @click="abandonClaim"
          title="Abandon checkin"
          v-show="showAbandonClaim"
      >
          <PhosphorIconXCircle size="24" color="currentColor" class="icon" />
      </button>
  </div>
</template>

<script setup lang="ts">
import { MP_CHECKIN_ABANDONED } from "~/plugins/mixpanel.client";

const { $mixpanel } = useNuxtApp();
const config = useRuntimeConfig();
const route = useRoute();

const showAbandonClaim = computed(() => route.name !== "index");

const abandonClaim = () => {
    $mixpanel(MP_CHECKIN_ABANDONED);
    resetClientStorage(true);
};
</script>
