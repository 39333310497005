<template>
  <div class="flex self-center container mx-auto justify-center max-w-2xl">
    <LoadSpinner v-if="isLoading" />
    <template v-else-if="!isLoading || isClaimFetched">
      <FindYourStayCard v-if="!isClaimFetched" class="max-w-2xl" />
    </template>
  </div>
</template>

<script lang="ts" setup>
const { getReservation, setClaimError, claimByReferenceCode } = useWebsiteStore();
const { isClaimFetched, reservationStage, referenceCode } = storeToRefs(useWebsiteStore());

const isLoading = ref(true);
const route = useRoute()

if ('shackle_reference_code' in route.query) {
  const code = route.query.shackle_reference_code as string
  const lengthValid = code.length === 7
  const containsOnlyAlphaNumeric = /^[a-zA-Z0-9]+$/.test(code)

  referenceCode.value = ''

  if (lengthValid && containsOnlyAlphaNumeric) referenceCode.value = code
}

onMounted(async () => {
  if (isClaimFetched.value && localStorage.getItem("reservationName")) {
    try {
      await getReservation(String(localStorage.getItem("reservationName")));
      isLoading.value = false
      if (
        ["CANCELED", "PENDING_CHECK_OUT", "PENDING_CHECK_OUT_OTHER_WINDOWS_REQUIRE_CLOSURE", "CHECKED_OUT"].includes(
          reservationStage.value
        )
      ) {
        resetClientStorage(false, () => setClaimError('That reservation cannot be accessed anymore.'))
      }
    } catch (error) {
      // should only occur if session is no longer valid
      resetClientStorage(false, () => setClaimError('An error occured whilst retrieving your reservation.'))
    }
  }

  if (
    isClaimFetched.value &&
    ["CLAIMED", "PROCESSING_CHECK_IN_REQUEST", "PENDING_CHECK_IN_VERIFICATION"].includes(reservationStage.value)
  ) {
    navigateTo("/check-in");
  }
  if (isClaimFetched.value && ["PENDING_CHECK_IN", "CHECKED_IN", "DUE_OUT"].includes(reservationStage.value)) {
    navigateTo("/confirmation");
  }

  if (!isClaimFetched.value && referenceCode.value) {
    try {
      await claimByReferenceCode(referenceCode.value)
    } catch (error) {
      setClaimError('That code is invalid. Please double check it and try again.')
      isLoading.value = false;
    }
  }

  isLoading.value = false;
});
</script>
