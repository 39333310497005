<template>
  <div class="w-full bg-white border-b sticky top-0 z-10 py-2">
    <div class="container lg:max-w-[1140px] text-teal w-full flex h-full items-center">
      <span class="text-[13px] flex-shrink-0">{{ current }} / {{ guestCount }} guests completed</span>
      <span class="w-full relative rounded-lg h-1 bg-teal bg-opacity-20 my-2 overflow-hidden ml-2">
        <span class="min-w-2 bg-teal absolute rounded-l-lg rounded-r-lg left-0 top-0 bottom-0 transition-all" :style="{'width': `${(100 / (guestCount)) * current}%` }"></span>
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
const {
  guestCount
} = storeToRefs(useWebsiteStore())

defineProps({
  current: {
    type: Number,
    default: 0
  }
})
</script>
