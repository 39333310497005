<template>
  <Card :has-bg="!inlineLayout" :has-border="!inlineLayout" v-if="property" :inline="inlineLayout" class="overflow-hidden rounded-xl">
    <img
      v-if="!hideHero"
      :src="assets.hero"
      class="rounded-md overflow-hidden object-cover @md:col-span-full transition-all"
    />

    <h1 class="text-[#2A2E43] text-2xl tracking-wider" :class="{'text-lg': !inlineLayout }">Your reservation details</h1>

    <div class="px-3 inline-flex items-center uppercase bg-teal text-white h-7 rounded-2xl space-x-1">
      <PhosphorIconClock weight="bold" class="w-4 h-4"/>
      <span class="text-[11px] font-bold tracking-widest">Check-In Open</span>
    </div>

    <div class="gap-2 lg:gap-4">
      <div class="w-full">
        <span class="font-medium tracking-wide text-[#7F828E]">Check-In</span>
        <div class="w-full border-b py-2 mb-4">
          <span :class="{'text-2xl': !inlineLayout }" class="text-lg font-medium tracking-wide text-navy">{{ dates.checkInTime }}</span>
        </div>

        <span class="font-medium tracking-wide text-[#7F828E]">Check out</span>
        <div class="w-full border-b py-2 mb-4">
          <span :class="{'text-2xl': !inlineLayout }" class="text-lg font-medium tracking-wide text-navy">{{ dates.checkOutTime }}</span>
        </div>

        <ul class="flex flex-col justify-between space-y-3 @md:mt-1 lg:mt-2">
          <li class="text-zinc-800 flex items-center space-x-2 font-medium">
            <PhosphorIconBed class="text-[#7F828E] w-6 h-6" />
            <span :class="{'text-lg': !inlineLayout }">{{ dates.nights }}</span>
          </li>
          <li class="text-zinc-800 flex items-center space-x-2 font-medium">
            <PhosphorIconUsers class="text-[#7F828E] w-6 h-6" />
            <span :class="{'text-lg': !inlineLayout }">{{ guests }}</span>
          </li>
        </ul>

        <div class="text-xs text-[#7F8084] mt-4">
          <p>Checking in now will not impact your cancellation policy.</p>
        </div>
      </div>
    </div>
  </Card>
</template>

<script lang="ts" setup>
import type { Reservation } from '~/grpc/proto/shackle/backend/backend_pb';

const config = useRuntimeConfig()
const { reservation, property } = storeToRefs(useWebsiteStore())

const props = defineProps<{
  hideHero?: boolean
  inlineLayout?: boolean
}>()

const imagePrefix = computed(() => {
  const supportedProperties = ['imperial-london', 'montcalm-london']
  // default to shackle property if property is not supported
  const name = property.value.name.includes(supportedProperties) ? property.value.name : 'properties/shackle'
  return `${config.public.webAssetUrl}/${name}/media`
})

const assets = computed(() => {
  const media = property.value?.media
  const includesId = (link: string) => link.includes(property.value?.name)

  return {
    hero: includesId(media.bannerImageLink) ? media.bannerImageLink : `${imagePrefix.value}/banner.jpg`,
  }
})

const getTimezoneAdjustedCheckinOutTimes = (reservation: Reservation) => {
  const propertyTimeZone = reservation.propertyTimeZone

  if (!reservation) {
    throw Error('No reservation present for that claim.');
  }

  const formatter = (date: Date, timeZone: string): string => {
    const intlFormatter = new Intl.DateTimeFormat('en-GB', {
      weekday: 'short',
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
      timeZone
    })

    // workaround - safari slaps an "at" in the output string for no reason whatsoever
    const [weekday, day, month, year, time, meridiem] = intlFormatter.format(date).split(' ').filter(i => i !== 'at')
    return `${weekday.replace(',', '')}, ${day} ${month}, ${year.replace(',', '')} at ${time}${meridiem.toLowerCase()}`;
  };

  const checkInToString = new Date(`${reservation.earliestCheckInTime}`)
  const checkOutToString = new Date(`${reservation.latestCheckOutTime}`)

  const tzAdjustedCheckInTime = formatter(checkInToString, propertyTimeZone);
  const tzAdjustedCheckOutTime = formatter(checkOutToString, propertyTimeZone);

  return {
    checkInTime: tzAdjustedCheckInTime,
    checkOutTime: tzAdjustedCheckOutTime
  }
}

const dates = computed(() => {
  if (!reservation.value) return {}
  if (!property.value) return {}

  const checkIn = new Date(`${reservation.value.earliestCheckInTime}`.split('T')[0])
  const checkOut = new Date(`${reservation.value.latestCheckOutTime}`.split('T')[0])

  const { checkInTime, checkOutTime } = getTimezoneAdjustedCheckinOutTimes(reservation.value)

  const differenceInTime = checkOut.getTime() - checkIn.getTime();
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);
  const nightCount =  Math.max(0, Math.floor(differenceInDays));

  return {
    nights: `${nightCount} night${nightCount > 1 ? 's' : ''}`,
    checkInTime,
    checkOutTime
  }
})

const guests = computed(() => {
  if (!reservation.value) return ``
  const guestCount = reservation.value.guestCount
  return `${guestCount} guest${guestCount > 1 ? 's' : ''}`
})
</script>
