<template>
  <div class="w-full">
    
<FormKitLazyProvider config-file="true">
<GuestCountChip v-if="guests.length > 1" :guestCount="activeFormIndex + 1" class="mb-4" />
    <h1 class="text-2xl text-navy mb-1">Please enter {{ activeFormIndex > 0 ? "guest's" : "your" }} details</h1>
    <p class="text-[16px] mt-2 mb-4 text-navy-600">The hotel requires this information to be able to process your check-in.</p>

    <FormKit
      id="form"
      ref="form"
      type="form"
      name="form"
      v-model="formModel"
      :actions="false"
      @input="onInput"
      @submit="onSubmit"
    >
      <FormKitSchema :schema="combinedFormkitSchema"  />
      <template #actions>
        <CheckInFormPolicyDetails />
        <div class="formkit-actions">
          <div
            class="flex space-x-5 lg:static lg:border-t-0 lg:bg-transparent lg:p-0 lg:justify-end"
            :class="{'fixed bottom-0 left-0 right-0 z-10 bg-white p-3 border-t': isSingleGuest }"
          >
            <SButton :key="button.label" v-for="button in submitButtonConfig" v-bind="button">
              {{ button.label }}
            </SButton>
          </div>
        </div>
      </template>
    </FormKit>
</FormKitLazyProvider>

  </div>
</template>

<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
import '@/utils/formkit/fkShackleTheme.css'
import { getNode } from '@formkit/core'
import { FormKitSchema, useFormKitNodeById } from '@formkit/vue'
import { useLocalStorage, useDebounceFn, useMediaQuery } from '@vueuse/core'
import {
  generateRequiredFieldSchema,
  mapResponseToFormKitSchema,
  buildSubmitFormPayload
} from './checkInConst'

const isMobileLayout = useMediaQuery('(max-width: 1023px)')
const formModel = useLocalStorage<Record<string, any>>('shackle-formModel', {}, { initOnMounted: true })
const formResponseSchema = useLocalStorage<any[]>('shackle-formResponseSchema', [], { initOnMounted: true })

const { initializeRegistrationForm, submitForm, formByGuestId } = useCheckInStore()
const { guests, completedGuests, reservationRequiresPayment } = storeToRefs(useWebsiteStore())

const emit = defineEmits(['complete', 'update-progress'])

const activeFormIndex = ref()

const isSingleGuest = computed(() => guests.value.length === 1)
const submitButtonLabel = computed(() => activeFormIndex.value === guests.value.length - 1 && !reservationRequiresPayment.value ? 'Complete check-in' : 'Next')

const submitButtonConfig = computed(() => {
  const buttons = []

  const submitButtonBase = {
    label: submitButtonLabel.value,
    class: `w-full lg:max-w-[160px] text-sm px-2`,
    padX: false
  }

  if (activeFormIndex.value > 0) buttons.push({
    label: 'Back',
    class: 'w-full lg:max-w-[160px] text-sm',
    theme: 'secondary',
    onClick: () => onBack(),
    disabled: activeFormIndex.value === 0
  })

  buttons.push(isMobileLayout.value ? {
    ...submitButtonBase,
    disabled: (!formConfig.canSubmit && !isSingleGuest.value) || (isSingleGuest.value && !formConfig.canSubmit)
  } : {
    ...submitButtonBase,
    disabled: !formConfig.canSubmit
  })

  return buttons
})

const combinedFormkitSchema = computed(() => {
  const guestType = guests.value[activeFormIndex.value]?.guestType
  return [
    ...generateRequiredFieldSchema(guestType),
    ...formResponseSchema.value
  ]
})

const inputsAsBasicFormKitSchema = computed<never[]>(() => {
  const form = formByGuestId(guests.value[activeFormIndex.value]?.guestId)
  if (!form) return []
  return form.field.map(mapResponseToFormKitSchema)
})

const initialiseForm = useDebounceFn(async (formData) => {
  const res = await initializeRegistrationForm(formData)
  if (!res?.error) {
    formConfig.isInitialised = true

    if (formData.countryOfResidence) {
      getNode('nationality')?.input(formData.countryOfResidence)
      getNode('document_issuing_country')?.input(formData.countryOfResidence)
    }
  }
}, 400)

const formConfig = reactive({
  isInitialised: false,
  didSubmit: false,
  canSubmit: false
})

const onBack = () => activeFormIndex.value = Math.max(0, activeFormIndex.value -1)

const onInput = async (formData: Record<string, any>) => {
  await initialiseForm(formData)

  formResponseSchema.value = inputsAsBasicFormKitSchema.value

  const formIsValid = toRef(getNode('form')?.context?.state ?? {}, 'valid' as never)
  const allMandatoryFieldsFilled = formResponseSchema.value.filter((field) => field.required).every((field) => formData[field.name])

  if (formData.guestType === 'PRIMARY') {
    formConfig.canSubmit = allMandatoryFieldsFilled && formIsValid
  } else {
    formConfig.canSubmit = formIsValid
  }

  if (formData.status === 'COMPLETED') {
    formConfig.canSubmit = true
    return
  }

  emit('update-progress', formConfig.canSubmit ? activeFormIndex.value + 1 : activeFormIndex.value)
}

const onSubmit = async (formData: Record<string, any>) => {
  const submitFormStructure = formByGuestId(guests.value[activeFormIndex.value].guestId)
  const submitPayload = buildSubmitFormPayload(formData, submitFormStructure)
  try {
    await submitForm(submitPayload)
    formConfig.didSubmit = true
    onComplete()
  } catch (error) {
    console.error(error)
  }
}

const onComplete = () => {
  if (guests.value.length === 1) {
    emit('complete')
    return
  }

  if (completedGuests.value.length !== guests.value.length) {
    activeFormIndex.value++
  } else {
    emit('complete')
  }
}

onMounted(() => {
  activeFormIndex.value = [...guests.value].map(g => g.status).findIndex(status => status !== 'COMPLETED') === 0 ? 0 : completedGuests.value.length - 1
  if (completedGuests.value.length === guests.value.length && !reservationRequiresPayment.value) {
    emit('complete')
  }
})

watch(activeFormIndex, async () => {
  useFormKitNodeById('form', (node) => {

    if (formConfig.didSubmit) {
      formConfig.didSubmit = false
      formModel.value = {}
      formResponseSchema.value = []
      node.reset()
    }

    const guest = guests.value[activeFormIndex.value]
    if (guest) node.input(guest)
  })
})
</script>
