<template>
  <CheckInFormProgressBar :current v-if="guests.length > 1 && checkinStarted" />
  <div
    class="container flex py-4"
    :class="{
      'flex-col lg:flex-1 lg:max-w-[480px]': !checkinStarted,
      'lg:justify-between lg:max-w-[1140px] h-full lg:h-auto': checkinStarted
    }" v-auto-animate>
    <StayDetailCard
      :class="{
        'hidden lg:block lg:max-w-xs': checkinStarted,
        'max-w-lg mx-auto': !checkinStarted
      }"
      :inline-layout="!checkinStarted"
      :hide-hero="!checkinStarted"
    />
    <div v-if="!checkinStarted" class="fixed w-screen bottom-0 left-0 right-0 py-3 border-t bg-white lg:static lg:bg-transparent lg:border-t-0 lg:p-0 lg:w-auto lg:mt-6">
      <div class="container lg:px-0">
        <SButton class="w-full @md:col-span-full" @click="startCheckIn">
          Start check-in
        </SButton>
      </div>
    </div>
    <CheckInFormStepper
      v-if="checkinStarted"
      class="lg:max-w-[740px]"
      @complete="onFormsComplete"
      @update-progress="(step) => current = step"
    />
  </div>
</template>

<script lang="ts" setup>
import { useLocalStorage } from '@vueuse/core'
const { getReservation, getListGuestsForReservation } = useWebsiteStore();
const { isClaimFetched, guests, reservationRequiresPayment } = storeToRefs(useWebsiteStore())
definePageMeta({
  middleware: '01-is-authenticated',
  layout: 'forms'
})

const checkinStarted = useLocalStorage('shackle-startedCheckin', false, { initOnMounted: true })
const startCheckIn = () => checkinStarted.value = true
provide('checkinStarted', checkinStarted)

const onFormsComplete = () => {
  if (reservationRequiresPayment.value) {
    navigateTo('/payment')
  } else {
    navigateTo('/confirmation')
  }
}

const current = ref()

onMounted(async () => {
  const reservationName = localStorage.getItem('reservationName')

  if (!isClaimFetched.value) navigateTo('/')

  if (isClaimFetched.value && reservationName) {
    await getReservation(String(reservationName));
    await getListGuestsForReservation()
  }
})
</script>
