<template>
  <div class="flex flex-col text-white text-center items-center space-y-4">
    <h2 class="text-5xl mx-12 font-normal">Loading your stay</h2>
    <PhosphorIconSpinnerGap size="48" class="load-spinner" />
  </div>
</template>

<style lang="postcss">
.load-spinner {
  animation: spin 1s steps(8) infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
