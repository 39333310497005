<template>
  <div class="bg-white py-3 border-b border-[#ccccce]">
    <div class="container h-full flex items-center justify-between">
      <h1
        class="text-navy text-2xl tracking-wide"
        data-testid="appTitle"
      >
        Online check-in
      </h1>
      <AppActions class="text-gray-500" />
    </div>
  </div>
</template>
