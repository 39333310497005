<template>
  <div :style="`background-image:url(${assets.hero});`" class="form-header bg-cover bg-bottom flex" v-auto-animate>
    <div class="container relative p-5 flex items-end lg:max-w-[1140px] transition">
      <div>
        <img :src="assets.logo" class="max-h-11 filter brightness-0 invert" />
        <p class="text-[15px] text-[#E7E8E9] mt-3 leading-none">Online check-in</p>
      </div>
      <div class="absolute right-0 top-0 p-5 @2xl:bottom-0 @2xl:top-auto">
        <AppActions class="text-[#E7E8E9]" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useLocalStorage } from '@vueuse/core'

const config = useRuntimeConfig()
const { property } = storeToRefs(useWebsiteStore())

const isPaymentPage = inject('isPaymentPage')

const checkinStarted = useLocalStorage('shackle-startedCheckin', false, { initOnMounted: true })

const imagePrefix = computed(() => {
  const supportedProperties = ['imperial-london', 'montcalm-london']
  // default to shackle property if property is not supported
  const name = property.value?.name.includes(supportedProperties) ? property.value?.name : 'properties/shackle'
  return `${config.public.webAssetUrl}/${name}/media`
})

const assets = computed(() => {
  const media = property.value?.media
  const includesId = (link: string) => link?.includes(property.value?.name)

  return {
    hero: includesId(media?.bannerImageLink) ? media?.bannerImageLink : `${imagePrefix.value}/banner.jpg`,
    logo: includesId(media?.logoImageLink) ? media?.logoImageLink : `${imagePrefix.value}/logo.png`
  }
})
</script>

<style lang="css" scoped>
.form-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: contain;
  background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,.7));
  z-index: 0;
}
</style>
